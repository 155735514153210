body {
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  background:  #eee;
  font-family: "Roboto",sans-serif;

  --light-pink: hsla(0, 100%, 84%, 1);
  --deep-champagne: hsla(33, 100%, 82%, 1);
  --lemon-yellow-crayola: hsla(62, 100%, 86%, 1);
  --tea-green: hsla(110, 100%, 87%, 1);
  --celeste: hsla(185, 100%, 80%, 1);
  --baby-blue-eyes: hsla(217, 100%, 81%, 1);
  --maximum-blue-purple: hsla(249, 100%, 85%, 1);
  --mauve: hsla(300, 100%, 89%, 1);
  --baby-powder: hsla(60, 100%, 99%, 1);
}

ul{
  padding-left: 0px;
}

li{
  list-style-type: none;
  padding-left:0px;
}


h2{
  margin:0px;
  padding:0px;
}